/* eslint-disable array-callback-return */
<template>
  <div class="divvy-form broker auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 divvy-form__container">
      <!-- Login-->
      <b-col lg="6" class="align-items-center auth-bg px-2">
        <b-col sm="6" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="divvy-form__form auth-login-form mt-3"
              @submit.prevent="loginUser">
              <b-link :to="{ name: 'site-home' }">
                <div class="broker__logo" />
              </b-link>

              <h1 class="divvy-form__title">Faça seu login</h1>

              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider name="Email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="login.userNameOrEmailAddress"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    placeholder="seuemail@exemplo.com.br" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                </div>
                <validation-provider name="Senha" rules="required">
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="login.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group class="mb-2">
                <div class="d-flex justify-content-between">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1">
                    Lembre de mim
                  </b-form-checkbox>

                  <b-link
                    :to="{ name: 'broker-reset-password' }"
                    class="forgot-password">
                    <small>Esqueceu sua senha?</small>
                  </b-link>
                </div>
                <b-row class="mt-3">
                  <b-col md="6">
                  <!-- submit buttons -->
                    <b-button
                      class="divvy-form__button"
                      type="submit"
                      variant="primary"
                      block
                      @click="validationForm">
                      Entrar
                    </b-button>
                  </b-col>
                  <b-col md="6">
                  <!-- submit buttons -->
                    <b-button
                      class="divvy-form__button"
                      type="submit"
                      variant="secondary"
                      block
                      :to="{ name: 'login-profile' }">
                      Voltar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">Ou continuar com</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
              <b-img
                class="logo-size"
                :src="require('@/assets/images/icons/social/icon_gmail.png')"
                alt="Gmail" 
                @click="loginGoogle"/>
            </b-link>
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
              <b-img
                class="logo-size"
                :src="require('@/assets/images/icons/social/icon_facebook.png')"
                alt="Gmail"
                @click="logInWithFacebook"/>
            </b-link>
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
              <b-img
                class="logo-size"
                :src="require('@/assets/images/icons/social/icont_witter.png')"
                alt="Gmail"
                @click="logInWithTwitter"/>
            </b-link>
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
            </b-link>
          </div>

          <b-card-text class="text-center mt-3">
            <span>Ainda não possui conta?</span>
            <b-link @click="goToRegister()" class="link-register-broker"> 
              <span>&nbsp; Faça agora!</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->

      <!-- Left Text-->
      <b-col class="register-background">
        <div class="register-background-text">
          <h1 class="register-background-text__title">
            Compartilhe imóveis e ganhe comissões rápidas e fáceis
            <span class="register-background-text__title--broker">!</span>
          </h1>
          <p class="register-background-text__paragraph">
            Simples, fácil e seguro para qualquer pessoa investir no mercado
            imobiliário.
          </p>
        </div>
      </b-col>

      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import menu from '../menu/menu'
import VueGapi from 'vue-gapi'
import Vue from 'vue'
import twitter from '@/libs/twitter';

Vue.use(VueGapi, {
  apiKey: Vue.prototype.gapiKey,
  clientId: Vue.prototype.gapiClientId,
  scope: 'openid profile email',
  plugin_name:'Divvy'
})

const FB_ID = Vue.prototype.fbId

const LoginWithTwitter = require('login-with-twitter')

const tw = new LoginWithTwitter({
  consumerKey: Vue.prototype.twitterKey,
  consumerSecret: Vue.prototype.twitterSecret,
  callbackUrl: 'http://localhost:8080/twitter/callback'
})

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentLogin: null,
      status: '',
      password: '',
      userEmail: '',
      error: '',
      errors: '',
      warning: '',
      sideImg: require('@/assets/images/pages/login/img-login.png'),
      imgLogo: require('@/assets/images/logo/logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      login: {
        serNameOrEmailAddress: null,
        password: null,
      },
      menu,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    localStorage.setItem('color', '#BC7DF0')
    localStorage.setItem('color-bg', '#ede7f6')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('encryptedAccessToken')
    localStorage.removeItem('role')
    localStorage.removeItem('name')
    localStorage.removeItem('menu')
    localStorage.removeItem('jwt')
    localStorage.removeItem('id')
    localStorage.removeItem('quiz')

    if (typeof FB === "undefined") {
        this.fbInit()
    } else {
        window.FB.XFBML.parse();
    }
    this.$gapi.getGapiClient()
    if (this.$route.query.oauth_token && this.$route.query.oauth_verifier) {
      this.loginTwitterCallback(this.$route.query.oauth_token, this.$route.query.oauth_verifier)
    }
  },
  methods: { 
    
    loginGoogle() {
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
            this.googleLoginStatusChangeCallback(
                gapi.auth2.getAuthInstance().isSignedIn.get()
            )
        })
    },

    googleLoginStatusChangeCallback(isSignedIn) {
        if (isSignedIn) {
          const model = {}
          model.authProvider = 'Google';
          model.providerAccessCode = gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getAuthResponse().access_token;
          model.providerKey = gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getBasicProfile()
              .getId()
          this.loginExternal(model)
        }
    },

    logInWithTwitter() {
      twitter.login('broker/login')
    },

    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          return true
        }
      })
    },
    
    async loginUser() {
      try {
        const response = await this.$http.post(
          '/api/TokenAuth/AuthenticateBroker',
          this.login,
          { validateStatus: () => true },
        )
        this.makeLogin(response)
      } catch (error) {
        this.error = error.message
        this.msgError()
      }
    },

    logInWithFacebook() {
      let cc = this
      FB.login((response) => {
        if (response.authResponse) { 
          const auth = response.authResponse
          const model = {
            authProvider: 'Facebook',
            providerKey: auth.userID,
            providerAccessCode: auth.accessToken,
            singleSignIn: false,
          }
          cc.loginExternal(model)
        } else {
          this.logInWithFacebook()
        }
      })
    return false;
  },
  
    async loginTwitterCallback(token, verifier){
      const response = (await this.$http.post(
          `/api/Twitter/GetAccessToken?token=${token}&verifier=${verifier}`,
          this.login,
          { validateStatus: () => true },)).data.result 
      let auth = await twitter.getAuthorizationOAuth(response.accessToken, response.accessTokenSecret)
      
      const userinfoResponse = (await this.$http.post(
          `/api/Twitter/GetUserinfo`,
          {tokenOauth: auth},
          { validateStatus: () => true },)).data.result 

      const model = {}
      model.authProvider = 'Twitter';
      model.providerAccessCode = auth
      model.providerKey = userinfoResponse.id_str
      this.loginExternal(model)
    },
  
    fbInit() {
      window.fbAsyncInit = function() {
          FB.init({
              appId: FB_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: "v8.0"
          });
          FB.AppEvents.logPageView();
      };
      (function(d, s, id) {
          var js,
              fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
              return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "//connect.facebook.net/fi_FI/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },

    async makeLogin(response){
      if (response.data.error) {
        this.error = response.data.error.message
        this.msgError()
        return
      }

      if (response.status === 500) {
        this.warning = 'Serviço fora de operação temporariamente!'
        this.msgWarning()
        return
      }

      this.error = ''
      if (response.status === 303) {
        this.warning = 'Caro usuário a equipe Divvy está analisando seu cadastro, logo entraremos em contato.'
        this.msgWarning()
        return
      }
      const { userId } = response.data.result
      const { accessToken } = response.data.result
      const { refreshToken } = response.data.result
      const { encryptedAccessToken } = response.data.result

      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('encryptedAccessToken', encryptedAccessToken)
      localStorage.setItem('id', userId)

      const current = await this.getResponseCurrentLogin().then(r =>
        JSON.parse(JSON.stringify(r)))

      const rName = current.data.result.user.name
      const nameToUpper = rName[0].toUpperCase() + rName.substr(1)
      localStorage.setItem('name', nameToUpper)

      const rRole = current.data.result.user.roles[0]
      // eslint-disable-next-line no-unused-vars
      const roleToUpper = rRole[0].toUpperCase() + rRole.substr(1)

      // eslint-disable-next-line array-callback-return,consistent-return
      const getRole = current.data.result.user.roles.filter(currentElement => {
        if (currentElement === 'Admin') {
          return 'Admin'
        }
        if (currentElement === 'BROKER') {
          return 'BROKER'
        }
        if (currentElement === 'PRE_BROKER') {
          return 'PRE_BROKER'
        }
      })

      localStorage.setItem('role', getRole[0])

      switch (getRole[0]) {
        case 'Admin':
          localStorage.setItem('menu', JSON.stringify(this.menu.admin))
          localStorage.setItem('color', '#F8AC18')
          localStorage.setItem('color-bg', '#fff7e8')
          break
        case 'BROKER':
          localStorage.setItem('menu', JSON.stringify(this.menu.broker))
          localStorage.setItem('color', '#BC7DF0')
          localStorage.setItem('color-bg', '#ede7f6')
          break
        case 'User':
          localStorage.setItem('menu', JSON.stringify(this.menu.user))
          localStorage.setItem('color', '#F8AC18')
          localStorage.setItem('color-bg', '#fff7e8')
          break
        default:
          // Corretor com cadastro pendente de avaliaçãos
          localStorage.setItem('menu', JSON.stringify(this.menu.brokerAnalysis))
          localStorage.setItem('color', '#BC7DF0')
          localStorage.setItem('color-bg', '#ede7f6')
      }
      document.documentElement.style.setProperty('--color', localStorage.getItem('color'))
      document.documentElement.style.setProperty('--color-bg', localStorage.getItem('color-bg'))
      if (refreshToken) {
        localStorage.setItem('jwt', true)
        if (response.data.result.documentsPending === true) {
          // Corretor com cadastro pendente de avaliação
          this.msgPending()
          setTimeout(() => {
            this.$router.push('/broker/account')
          }, 5100)
        } else if (response.status === 303) {
          this.error = 'Caro usuário a equipe Divvy está analisando seu cadastro, logo entraremos em contato.'
          this.msgWarning()
        } else {
          this.msgSuccess()
          setTimeout(() => {
            this.$router.push('/dashboard')
          }, 2700)
        }
      } else {
        this.error = response.data.result.error.message
        this.msgError()
      }
    },

    async loginExternal(model){  
        try {
        const response = await this.$http.post(
          '/api/TokenAuth/AuthenticateExternalBroker',
          model,
          { validateStatus: () => true },
        )
         if (response.data.error) {
          if(response.data.error.message === 'NOT_FOUND'){
            this.redirectToRegister(model)
          } else {
            this.error = response.data.error.message
            this.msgError()
          }
          return
        } else {
          this.makeLogin(response)
        }
      } catch (error) {
      }
    },

    redirectToRegister(auth){
      this.$router.push('register')
      localStorage.setItem('externalLogin', true)
      localStorage.setItem('authProvider', auth.authProvider)
      localStorage.setItem('providerKey', auth.providerKey)
      localStorage.setItem('providerAccessCode', auth.providerAccessCode)
    },

    async getResponseCurrentLogin() {
      const response = await this.$http.get(
        '/api/services/app/Session/GetCurrentLoginInformations',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      return response
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Login efetuado com sucesso',
        text: 'Você será redirecionado para área administrativa',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao efetuar o login',
        text: this.error,
        showConfirmButton: false,
        timer: 3600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    // pending
    msgPending() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção',
        text: 'Seus dados estão incompletos. \n Você será redirecionado para tela de preenchimento.',
        showConfirmButton: false,
        timer: 5000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    goToRegister(){
      localStorage.removeItem('externalLogin')
      this.$router.push({ name: 'broker-register' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '../sass/variables.scss';
@import '../sass/divvy-form.scss';
@import '../sass/register-background-text.scss';
@import '../sass/broker.scss';
a.link-register-broker {
  color: #bc7df0;
}
.register-background {
  display: none;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_broker.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
.logo-size {
  width: 40px;
}

.icon-background-color-white {
  background-color: #fff !important;
  margin-right: 20px;
}

.divider {
  max-width: 80%;
}
</style>
